<template>
    <div>
        <div class="main-content">
            <h1 class="h1">购买记录</h1>
            <div class="video-list" v-if="list.length>0">
                <div class="video-item cup" v-for="(item,index) in list" :key="index" @click="link_detail(item.id,item.type,item.vipSet)">
                    <div class="video-cover">
                        <img :src="item.showVideoStatus!=3?(item.photoUrl || ''):item.memberPhotoReport.photoUrl" alt="">
                        <div class="item_vip" v-if="item.vipSet==1">VIP</div>
						<div class="item_vip" v-else-if="item.vipSet==2">付费</div>
						<div class="item_vip" v-else>免费</div>
                        <div class="item_date" v-if="item.showVideoStatus!=3">{{item.videoLength ? changSecond(item.videoLength||'00:00') : '00:00'}}</div>
                    </div>
                    <div class="video-info">
                        <div class="video-info-title caochu">
                            {{item.showVideoStatus!=3?(item.videoTitle || ''):item.memberPhotoReport.reportText}}
                        </div>
                        <div class="video-info-summary mt10 caochu">
                            {{item.showVideoStatus!=3?(item.videoDetails || ''):item.memberPhotoReport.profiles}}
                        </div>
                        <div class="video-info-bottom">
                           <span>购买时间：{{item.payTime}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <nodata v-if="list.length<=0"></nodata>
            <div class="pagination">
                <el-pagination
                @current-change="currentChange"
                background
                :page-size="12"
                :hide-on-single-page="true"
                layout="prev, pager, next"
                :current-page="current"
                :total="total">
            </el-pagination>
            </div>
        </div>
        <gap height="50"></gap>
            <Homefooter ></Homefooter>
    </div>
</template>

<script>
    import {
		payCourseList
	} from '@/apiUrl/index.js'
	import {
		mapGetters
	} from 'vuex'
    export default {
        computed:{
			...mapGetters(['userInfo'])
		},
        data() {
            return {
                current: 1,
				isOK: true,
				list:[],
                total:100
            }
        },
        created() {
            this.getdata();
        },
        methods: {
            currentChange(val) {
                this.current = val;
                this.getdata();
            },
			getdata() {
				payCourseList({mid:this.userInfo.id,current:this.current,size:'12'}).then((res)=>{
					if(res.code==200){
						var arr = res.data.list;
                        this.total = res.data.count;
						this.list = this.list.concat(arr);
					}else{
						this.$msg(res.message);
					}
				})
			},
			changSecond(e) { //转换成秒
				return this.$secondToMinutes(e)
			},
			link_detail(id,type,vipSet){
                if(type==1){
                    this.$link('/video_detail?id=' + id + '&mode=video')
				}else if(type==2){
					if(vipSet==2){
                        this.$link('/video_detail2?id=' + id + '&mode=video')
					}else{
						this.$link('/video_detail1?id=' + id + '&mode=video')
					}
				}
			},
			cancel(id){
				this.$emit('fabulous',{value:'video',id})
			},
		},
    }
</script>

<style lang="less" scoped>
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-content {
    width: 100%;
    background-color: #fff;
    padding:15px;
    .h1 {
        height: 48px;
        line-height: 48px;
        font-size: 18px;
        font-weight: 600;
        color: #2e2e2e;
        padding-left: 16px;
        border-bottom: 1px solid #f0f0f2;
    }
}
.video-list {
    margin-top: 15px;
    .video-item {
        
        display: flex;
        // align-items: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #f2f2f5;
    }
    .video-cover {
        width: 230px;
        height: 150px;
        margin-right: 15px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            
        }
        .item_vip{
            position: absolute !important;
            right: 5px;
            top: 5px;
            height: 20px;
            font-size: 14px;
            line-height: 20px;
            padding: 0 12px;
            border-radius: 3px;
            background-color: #f6bd74;
            color: #9c6a27;
           
        }
        .item_date{
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            padding: 0 5px;
            border-radius: 7px;
            background-color: rgba(0, 0, 0, .5);
            color: #fff;
            position: absolute;
            right: 5px;
            bottom: 5px;
        }
    }
    .video-info {
        .video-info-title {
            font-size: 18px;
            font-weight: 600;
            color: #2e2e2e;
        }
        .video-info-bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            .el-progress {
                display: flex;
                align-items: center;
            }
        }
    }
}
</style>